<template>
  <div class="info">
    <modal :show="showModal">
      <h2>Så tävlar du</h2>
      <p class="-body-2"><strong>1.</strong> Köp 1 valfri produkt från Paulúns.</p>
      <p class="-body-2"><strong>2.</strong> Spara kvittot! Om du vinner ska du kunna visa upp det.</p>
      <p class="-body-2"><strong>3.</strong> Klicka på Starta & mixa din smoothiebowl.</p>
      <p class="-body-2"><strong>4.</strong> Du kan skicka in hur många bidrag som helst men bara vinna en gång.</p>
      <p class="-body-2"><strong>5.</strong> Paulúns Hälsoråd väljer ut de 20 bästa bidragen enligt vår BARA BRA MAT-filosofi. Utifrån kriterier som godast smak, hälsa och naturlighet.</p>
      <a href="javascript:;" @click="showRules(true)">Läs mer om BARA BRA MAT</a>
      <a href="javascript:;" @click="showRules(false)">Till tävlingsvillkoren</a>
      <Button :click="start">Starta & välj ingredienser</Button>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Modal, Button } from '@/components'

export default {
  components: {
    Modal,
    Button,
  },
  data: () => ({
    showModal: false,
  }),
  methods: {
    showRules(goodFood) {
      this.$store.dispatch('rule/showRules', { goodFood, show: true })
    },
    start() {
      this.showModal = false
      this.$store.dispatch('recipe/setInstructionsDisplayed')
      setTimeout(() => {
        this.$router.push({ name: 'ingredients' })
      }, 200)
    },
  },
  mounted() {
    if (!this.hasDisplayedInstructions) {
      this.showModal = true
      return
    }
    this.$router.push({ name: 'ingredients' })
  },
  computed: {
    ...mapGetters({
      hasDisplayedInstructions: 'recipe/hasDisplayedInstructions',
    }),
  },
}
</script>

<style lang="scss" scoped>

h2 {
  @include rem(font-size, 26px);
  @include rem(line-height, 34px);
  @include rem(margin-bottom, 10px);
  text-align: center;
  color: $green;
}

p,
a {
  display: inline-block;
  @include rem(margin-bottom, $gutter / 2);
}

.btn {
  @include rem(margin-top, $gutter);
  margin-bottom: 0;
  width: 100%;
}
</style>
